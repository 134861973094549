.input-file {
  position: relative;
  display: inline-block;
}
.input-file-text {
  padding: 0 10px;
  line-height: 50px;
  text-align: left;
  height: 50px;
  display: block;
  float: left;
  box-sizing: border-box;
  width: 214px;
  border-radius: 10px 0px 0 10px;
  border: 1px solid #ddd;
}
.input-file-btn {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  font-size: 14px;
  vertical-align: middle;
  color: rgb(255 255 255);
  text-align: center;
  border-radius: 0 10px 10px 0;
  background-color: #419152;
  line-height: 32px;
  height: 50px;
  padding: 10px 20px;
  box-sizing: border-box;
  border: none;
  margin: 0;
  transition: background-color 0.2s;
}
.input-file input[type="file"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

/* Focus */
.input-file input[type="file"]:focus + .input-file-btn {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* Hover/active */
.input-file:hover .input-file-btn {
  background-color: #59be6e;
}
.input-file:active .input-file-btn {
  background-color: #2e703a;
}

/* Disabled */
.input-file input[type="file"]:disabled + .input-file-btn {
  background-color: #eee;
}
