audio::-webkit-media-controls-panel {
    background-color: #E1E6FF;
}

/* Removes the timeline */
audio::-webkit-media-controls-timeline {
}

/* Removes the time stamp */
audio::-webkit-media-controls-current-time-display {}
audio::-webkit-media-controls-time-remaining-display {}
audio::-webkit-media-slider-thumb {
}
audio::-webkit-media-slider-container {
}

audio::-webkit-media-slider-container {
}

audio {
    outline: none;
}
